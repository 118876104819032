@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.container {
  @apply 2xl:w-[1600px] mx-auto 1sm:w-[100%]
}

.buttons {
  @apply bg-[#11d044] text-center border-[1px] border-[#11d044] text-white px-[10px] py-[5px] md:px-[27px] md:py-[10px] rounded-[27px] hover:bg-white hover:text-[#11d044]
}

.hoverbtn {
  @apply bg-transparent text-center border-[1px] border-[#11d044] text-[#11d044] px-[10px] py-[5px] md:px-[27px] md:py-[10px] rounded-[27px] hover:bg-[#11d044] hover:text-white
}

.blackbtn {
  @apply bg-white text-center text-black border-[1px] px-[10px] py-[5px] md:px-[27px] md:py-[10px] items-center border-black hover:bg-black hover:text-white
}

.chatbotbtn{
  @apply bg-[#11d044] text-white rounded px-[10px] py-[5px] h-[40px] text-center w-full cursor-pointer  border-[1px] border-[#11d044] hover:bg-white hover:text-[#11d044] mx-auto
}

.login_img_section {
  background: linear-gradient(rgba(2, 2, 2, .7), rgba(0, 0, 0, .7)), url('./assests/amazing-forest-sunset.jpg') center center;
  background-size: cover;
}

.modal .Modal {
  position: absolute;
  top: 40px;
  left: 40px;
  right: 40px;
  bottom: 40px;
  background-color: white;
  align-items: center;

}

@media (max-width: 600px)  {
  #video{
    width: 100vw !important;
  }
  #remoteVideo {
    display: flex;
    padding: 10px;
    flex-direction: column;
    gap: 20px;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }
}